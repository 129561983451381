<template>
  <main>
    <div class="page-content">
      <div v-if="isMessageSent">
        <p>Your message has been sent. You will hear back from us soon.</p>
      </div>
      <form v-else @submit.prevent="handleSend" class="edit-form">
        <h3>Send us a message</h3>
        <div class="form-outline mb-4">
          <label>Subject</label>
          <input type="text" v-model="subject" class="form-control" required />
        </div>
        <div class="form-outline mb-4">
          <label>Message</label>
          <textarea v-model="message" class="form-control long-text" required>
          </textarea>
        </div>
        <div class="error">{{ formError }}</div>
        <div class="row text-center">
          <div class="col">
            <div v-if="!isPending">
              <button class="btn btn-primary">Send Message</button>
            </div>
            <div v-else>
              <button class="btn btn-primary" disabled>
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Sending...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="p-4 text-center">
          <p>or call us</p>
          <h3>(804) 250-9380</h3>
        </div>
      </form>
    </div>
  </main>
  <Footer />
</template>

<script>
import Footer from "@/components/navigation/Footer.vue";
import { ref } from "@vue/reactivity";
import useCollection from "@/composables/useCollection";
import getUser from "@/composables/getUser";

export default {
  name: "Contact",
  components: { Footer },
  setup() {
    const isMessageSent = ref(false);
    const subject = ref(null);
    const message = ref(null);
    const formError = ref(null);
    const { user } = getUser();

    const { error, isPending, addDoc: addMessage } = useCollection("rhSupport");

    const handleSend = async () => {
      console.log("Send Message");
      formError.value = null;
      isMessageSent.value = false;
      await addMessage({
        name: user.value.displayName,
        to: "hello@roundhere.co",
        from: "support@roundhere.co",
        replyTo: `${user.value.displayName} <${user.value.email}>`,
        message: {
          subject: subject.value,
          text: `${message.value}\n\nLogged in Issuer: ${user.value.displayName}`,
          html: `${message.value}<br><br>Logged in Issuer: ${user.value.displayName}`
        },
        isLoggedIn: true,
        application: "raise"
      });
      if (error.value) {
        formError.value = error.value;
      } else {
        subject.value = message.value = "";
        isMessageSent.value = true;
        window.scrollTo(0, 0);
      }
    };

    return {
      isMessageSent,
      subject,
      message,
      formError,
      isPending,
      handleSend
    };
  }
};
</script>

<style>
</style>